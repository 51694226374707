<template>
  <div>
    <a-card :title="$t('出库通知单')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <CounterpartySelect
            v-model="searchForm.counterparty"
            :placeholder="$t('往来单位')"
            @change="search"
          ></CounterpartySelect>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_completed" :placeholder="$t('状态')" allowClear style="width: 100%" @change="search">
            <a-select-option key="0" :value="false">{{ $t("进行中") }}</a-select-option>
            <a-select-option key="1" :value="true">{{ $t("已完成") }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.search"
            :placeholder="$t('单据编号, 往来单位单号, 备注')"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" :md="12" :xl="12" style="margin-bottom: 12px; width: 500px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("导入") }}</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
            <!-- <a-button icon="download" @click="exportSummaryExcel">{{ $t("汇总导出") }}</a-button> -->
            <a-popconfirm :title="$t('确定删除吗?')" @confirm="bulkDelete">
              <a-button type="danger" icon="delete">{{ $t("批量删除") }}</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>

        <a-col :span="24" :md="12" :xl="12" style="margin-bottom: 12px; width: 400px">
          <a-button-group>
            <a-button @click="printDeliveryOrder">{{ $t("打印送货单") }}</a-button>
            <a-button @click="printPickingOrder">{{ $t("打印拣货单") }}</a-button>
            <a-button @click="openPrintPackingModal">{{ $t("打印箱号") }}</a-button>
            <a-button @click="printPackingOrder">{{ $t("打印装箱单") }}</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="addOrder">
            {{ $t("新增出库通知单") }}
          </a-button>
        </div>
      </a-row>

      <a-row>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :scroll="{ x: 1720 }"
          @change="tableChange"
        >
          <template slot="number" slot-scope="value, item">
            <a @click="detail(item)">{{ value }}</a>
          </template>
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button @click="detail(item)">{{ $t("详情") }}</a-button>
              <a-popconfirm :title="$t('确定强制完成吗?')" @confirm="complete(item)">
                <a-button type="primary">{{ $t("强制完成") }}</a-button>
              </a-popconfirm>
              <a-popconfirm :title="$t('确定作废重建吗?')" @confirm="voidAndRecreate(item)">
                <a-button type="danger">{{ $t("作废重建") }}</a-button>
              </a-popconfirm>
              <a-popconfirm :title="$t('确定作废吗?')" @confirm="voidItem(item)">
                <a-button type="danger">{{ $t("作废") }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>

    <a-modal v-model="printPackingModalVisible" title="打印箱号" @ok="printPackingNumber()">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item label="箱号">
          <a-input v-model="packingNumber" allowClear />
        </a-form-model-item>
        <a-form-model-item label="打印方式">
          <a-radio-group v-model="printModel" button-style="solid" style="width: 100%; text-align: center">
            <a-radio-button value="continuous" style="width: 50%">{{ $t("连续打印") }}</a-radio-button>
            <a-radio-button value="repeat" style="width: 50%">{{ $t("重复打印") }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="打印张数">
          <a-input-number v-model="printQuantity" allowClear style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { deliveryOrderTemplate, deliveryOrderImport } from "@/api/import";
import {
  deliveryOrderList,
  deliveryOrderVoid,
  deliveryOrderBulkDelete,
  deliveryOrderPrint,
  pickingOrderPrint,
  deliveryOrderComplete,
  printDeliveryPackingOrder,
} from "@/api/stockOut";
import { deliveryOrdersExport, deliveryOrderSummaryExport } from "@/api/export";
import { warehouseOption } from "@/api/option";
import { exportExcel } from "@/utils/excel";
import { columns } from "./columns.js";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import jrQrcode from "jr-qrcode";
import NP from "number-precision";
import JsBarcode from "jsbarcode";
import lodopPrintStockOutOrder from "@/views/printConfigs/printStockOutOrder";
import lodopPrintPickingOrder from "@/views/printConfigs/printPickingOrder";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    CounterpartySelect: () => import("@/components/CounterpartySelect/"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      warehouseItems: [],

      visible: false,
      targetItem: {},
      form: {},

      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,

      shengshiteLogo: require("@/assets/shengshite-logo.png"),
      printPackingModalVisible: false,
      packingNumber: "",
      printModel: "continuous",
      printQuantity: 1,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    packingLabelLength() {
      return this.$store.state.user.barConfig.packingLabelLength;
    },
    packingLabelWidth() {
      return this.$store.state.user.barConfig.packingLabelWidth;
    },
    stockOutPrintConfig() {
      return this.$store.state.user.stockOutOrderPrintConfig;
    },
    pickingPrintConfig() {
      return this.$store.state.user.pickingOrderPrintConfig;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.$functions.parseParams(this.$route.query);
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      deliveryOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    addOrder() {
      this.$router.push({ path: "/stock_out/delivery_order_create" });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    detail(item) {
      this.$router.push({ path: "/stock_out/delivery_task_detail", query: { id: item.id } });
    },
    voidItem(item) {
      deliveryOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    voidAndRecreate(item) {
      deliveryOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.$router.push({ path: "/stock_out/delivery_order_create", query: { id: item.id } });
      });
    },
    complete(item) {
      deliveryOrderComplete({ id: item.id }).then(() => {
        this.$message.success(this.$t("强制完成成功"));
        this.list();
      });
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要导出的出库通知单!"));
        return false;
      }
      deliveryOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, this.$t("出库通知单列表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    exportSummaryExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要导出的出库通知单!"));
        return false;
      }
      deliveryOrderSummaryExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, this.$t("出库通知单汇总"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      deliveryOrderTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("出库通知单导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        deliveryOrderImport(data)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            alert(this.$t("导入错误:") + "\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    bulkDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要删除的出库通知单!"));
        return false;
      }

      deliveryOrderBulkDelete({ ids: this.selectedRowKeys }).then(() => {
        this.$message.success(this.$t("删除成功"));
        this.list();
      });
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    printDeliveryOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的出库通知单!"));
        return;
      }

      deliveryOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        lodopPrintStockOutOrder(data, this.stockOutPrintConfig);

        //         const LODOP = getLodop();
        //         LODOP.PRINT_INIT("");
        //         LODOP.SET_PRINT_PAGESIZE(1, `"215mm"`, "279mm");

        //         for (let deliveryOrderItem of data) {
        //           let pages = Math.ceil(deliveryOrderItem.delivery_material_items.length / 8);

        //           let canvas = document.createElement("canvas");
        //           JsBarcode(canvas, deliveryOrderItem.number, {
        //             format: "CODE128",
        //             width: 2,
        //             height: 35,
        //             displayValue: true,
        //             background: "#ffffff",
        //             lineColor: "#000000",
        //           });
        //           let barcodeBase64 = canvas.toDataURL();

        //           let totalQuantity = 0;
        //           for (let item of deliveryOrderItem.delivery_material_items) {
        //             totalQuantity = NP.plus(totalQuantity, item.total_quantity);
        //           }

        //           for (let index = 1; index <= pages; index++) {
        //             let deliveryMaterialItems = deliveryOrderItem.delivery_material_items.slice((index - 1) * 8, index * 8);
        //             let tableRows = deliveryMaterialItems.map((item) => {
        //               return `
        // <tr>
        //   <td>${this.printText(item.material_item.number)}</td>
        //   <td>${this.printText(item.material_item.name)}</td>
        //   <td>${this.printText(item.material_item.unit)}</td>
        //   <td>${this.printText(item.total_quantity)}</td>
        // </tr>`;
        //             });

        //             const pageContent = `
        // <div style="padding: 16px">
        //   <div style="font-size: 24px; font-weight: 600; border-bottom: 3px solid">
        //     <span>NO.${deliveryOrderItem.number}</span>
        //     <span style="float: right;">${pages > 1 ? index : ""}</span>
        //   </div>

        //   <div style="margin-top: 8px; font-size: 32px; font-weight: 600">
        //     <div style="width: 40%; margin: auto; border-bottom: 3px solid; display: flex">
        //       <div style="width: 100%; text-align: center">${this.$t("送 货 单")}</div>
        //     </div>
        //   </div>

        //   <div style="margin-top: 8px; font-size: 14px; display: flex">
        //     <div style="width: 50%; text-align: center">${this.$t("制单日")}: ${moment().format("YYYY-MM-DD")}</div>
        //     <div style="width: 50%; text-align: center">${this.$t("订单号")}: ${this.printText(deliveryOrderItem.external_number)}</div>
        //   </div>

        //   <div style="margin-top: 8px; display: flex;">
        //     <div style="width: 50%; text-align: center; font-size: 24px; font-weight: 600;">
        //       <div style="padding-top: 8px">${deliveryOrderItem.client_item.name}</div>
        //     </div>
        //     <div style="width: 50%; text-align: center; ">
        //       <img src="${barcodeBase64}" />
        //     </div>
        //   </div>

        //   <div style="margin-top: 8px; font-size: 14px; display: flex">
        //     <div style="width: 35%">${this.$t("收货公司")}: ${this.printText(deliveryOrderItem.region_name)}</div>
        //     <div style="width: 65%; margin-left: 12px">${this.$t("地址")}: ${this.printText(deliveryOrderItem.receiving_address)}</div>
        //   </div>

        //   <div style="margin-top: 8px; font-size: 14px; display: flex">
        //     <div style="width: 35%">${this.$t("收货人")}: ${this.printText(deliveryOrderItem.customer_contact)}</div>
        //     <div style="width: 65%; margin-left: 12px">${this.$t("联系方式")}: ${this.printText(deliveryOrderItem.contact_phone)}</div>
        //   </div>

        //   <div style="margin-top: 16px; font-size: 14px">
        //     <table border="1" cellspacing="0" style="width: 100%; text-align: center">
        //       <tr>
        //         <th style="width: 25%">${this.$t("商品编号")}</th>
        //         <th style="width: 55%">${this.$t("商品名称")}</th>
        //         <th style="width: 10%">${this.$t("单位")}</th>
        //         <th style="width: 10%">${this.$t("数量")}</th>
        //       </tr>
        //       ${tableRows.join("")}
        //       <tr>
        //         <td colspan="2" style="text-align: left">${this.$t("合计")}: ${totalQuantity}</td>
        //         <td colspan="2" rowspan="2">
        //           ${this.$t(
        //             "为避免双方利益受损, 针对以上项目请您在接受货物时务必同送货方进行确认, 无误后再签收, 签收后视为验收合格。货物完好."
        //           )}
        //         </td>
        //       </tr>
        //       <tr>
        //         <td colspan="2" style="text-align: left">${this.$t("总重量(KG)")}:</td>
        //       </tr>
        //     </table>
        //   </div>

        //   <div style="margin-top: 16px; font-size: 14px; display: flex">
        //     <div style="width: 33%; border: 1px solid; padding: 4px">
        //       <div>${this.$t("发货人 （盖章/签字）")}</div>
        //       <div style="margin-top: 36px">${this.$t("发货日")}:</div>
        //     </div>
        //     <div style="width: 34%; border: 1px solid; padding: 4px; margin: 0 24px">
        //       <div>${this.$t("承运人 （盖章/签字）")}</div>
        //       <div style="margin-top: 36px">${this.$t("承运日")}:</div>
        //     </div>
        //     <div style="width: 33%; border: 1px solid; padding: 4px">
        //       <div>${this.$t("收货人 （盖章/签字）")}</div>
        //       <div style="margin-top: 36px">${this.$t("收货日")}:</div>
        //     </div>
        //   </div>
        // </div>
        //             `;

        //             LODOP.NewPage();
        //             LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
        //           }
        //         }

        //         LODOP.PREVIEW();
      });
    },
    printPickingOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的出库通知单!"));
        return;
      }

      const pickingOrderIds = [];
      for (const selectedRowKey of this.selectedRowKeys) {
        let index = this.items.findIndex((item) => item.id == selectedRowKey);
        if (index !== -1) {
          pickingOrderIds.push(this.items[index].picking_order);
        }
      }

      pickingOrderPrint({ ids: pickingOrderIds }).then((data) => {
        lodopPrintPickingOrder(data, this.pickingPrintConfig);

        //         const LODOP = getLodop();
        //         LODOP.PRINT_INIT("");
        //         LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        //         for (let pickingOrderItem of data) {
        //           let deliveryDate = pickingOrderItem.delivery_order_item.delivery_date;
        //           deliveryDate = deliveryDate ? moment(deliveryDate).format("YYYY-MM-DD") : "";

        //           let pickingMaterialItems = [];
        //           let totalQuantity = 0;
        //           let totalWeight = 0;
        //           let totalVolume = 0;
        //           let canvas = document.createElement("canvas");
        //           JsBarcode(canvas, pickingOrderItem.number, {
        //             format: "CODE128",
        //             width: 2,
        //             height: 35,
        //             displayValue: true,
        //             background: "#ffffff",
        //             lineColor: "#000000",
        //           });
        //           let barcodeBase64 = canvas.toDataURL();

        //           for (let pickingMaterialItem of pickingOrderItem.picking_material_items) {
        //             totalQuantity = NP.plus(totalQuantity, pickingMaterialItem.total_quantity);
        //             totalWeight = NP.plus(
        //               totalWeight,
        //               NP.times(pickingMaterialItem.total_quantity, pickingMaterialItem.material_item.weight || 0)
        //             );
        //             totalVolume = NP.plus(
        //               totalVolume,
        //               NP.times(pickingMaterialItem.total_quantity, pickingMaterialItem.material_item.volume || 0)
        //             );

        //             if (pickingMaterialItem.recommend_location_items.length > 0) {
        //               for (let recommendLocationItem of pickingMaterialItem.recommend_location_items) {
        //                 pickingMaterialItems.push({
        //                   material_number: pickingMaterialItem.material_item.number,
        //                   material_name: pickingMaterialItem.material_item.name,
        //                   material_spec: pickingMaterialItem.material_item.spec,
        //                   total_quantity: recommendLocationItem.total_quantity,
        //                   batch_number: pickingMaterialItem.batch_item.number,
        //                   location_number: recommendLocationItem.location_number,
        //                   status_display: recommendLocationItem.status_display,
        //                   remain_quantity: NP.minus(recommendLocationItem.inventory_quantity, recommendLocationItem.total_quantity),
        //                 });
        //               }
        //             } else {
        //               pickingMaterialItems.push({
        //                 material_number: pickingMaterialItem.material_item.number,
        //                 material_name: pickingMaterialItem.material_item.name,
        //                 material_spec: pickingMaterialItem.material_item.spec,
        //                 total_quantity: pickingMaterialItem.total_quantity,
        //                 batch_number: pickingMaterialItem.batch_item.number,
        //                 location_number: "",
        //               });
        //             }
        //           }

        //           let pages = Math.ceil(pickingMaterialItems.length / 14);
        //           for (let index = 1; index <= pages; index++) {
        //             let items = pickingMaterialItems.slice((index - 1) * 14, index * 14);
        //             let tableRows = items.map((item) => {
        //               return `
        // <tr>
        //   <td>${this.printText(item.material_number)}</td>
        //   <td>${this.printText(item.material_name)}</td>
        //   <td>${this.printText(item.material_spec)}</td>
        //   <td>${this.printText(item.total_quantity)}</td>
        //   <td>${this.printText(item.batch_number)}</td>
        //   <td>${this.printText(item.status_display)}</td>
        //   <td>${this.printText(item.location_number)}</td>
        //   <td>${this.printText(item.remain_quantity)}</td>
        // </tr>`;
        //             });

        //             let pageContent = `
        // <div style="padding: 8px">
        //   <div style="font-size: 36px; text-align: center">
        //     <span>${this.$t("拣货单")}</span>
        //     <span style="float: right;">${pages > 1 ? index : ""}</span>
        //   </div>

        //   <div style="margin-top: 8px; text-align: right">
        //     <img src="${barcodeBase64}" />
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 33%">${this.$t("货主")}: ${this.printText(pickingOrderItem.client_item.name)}</div>
        //     <div style="width: 34%">${this.$t("往来单位单号")}: ${this.printText(
        //               pickingOrderItem.delivery_order_item.external_number
        //             )}</div>
        //     <div style="width: 33%">${this.$t("出库单号")}: ${this.printText(pickingOrderItem.delivery_order_item.number)}</div>
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 33%">${this.$t("送达方名称")}: ${this.printText(pickingOrderItem.delivery_order_item.region_name)}</div>
        //     <div style="width: 67%">${this.$t("送货地址")}: ${this.printText(
        //               pickingOrderItem.delivery_order_item.receiving_address
        //             )}</div>
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 33%">${this.$t("仓库")}: ${this.printText(pickingOrderItem.warehouse_item.name)}</div>
        //     <div style="width: 34%">${this.$t("创建人")}: ${this.printText(pickingOrderItem.creator_item.name)}</div>
        //     <div style="width: 33%">${this.$t("创建时间")}: ${this.printText(pickingOrderItem.create_time)}</div>
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 33%">${this.$t("总重量(吨)")}: ${NP.times(totalWeight, 0.001)}</div>
        //     <div style="width: 34%">${this.$t("总体积(立方)")}: ${totalVolume}</div>
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 67%">${this.$t("备注")}: ${this.printText(pickingOrderItem.remark)}</div>
        //   </div>

        //   <div style="margin-top: 16px; font-size: 14px">
        //     <table border="1" cellspacing="0" style="width: 100%; text-align: center">
        //       <tr>
        //         <th>${this.$t("货品编号")}</th>
        //         <th>${this.$t("货品名称")}</th>
        //         <th>${this.$t("规格")}</th>
        //         <th>${this.$t("应拣数量")}</th>
        //         <th>${this.$t("批次编号")}</th>
        //         <th>${this.$t("质检状态")}</th>
        //         <th>${this.$t("推荐库位")}</th>
        //         <th>${this.$t("剩余库存")}</th>
        //       </tr>
        //       ${tableRows.join("")}
        //       <tr>
        //         <td>${this.$t("合计")}:</td>
        //         <td></td>
        //         <td></td>
        //         <td>${this.printText(totalQuantity)}</td>
        //         <td></td>
        //         <td></td>
        //         <td></td>
        //         <td></td>
        //       </tr>
        //     </table>
        //   </div>

        //   <div style="margin-top: 24px; font-size: 14px">
        //     ${this.$t("拣货人")}:
        //   </div>
        //   <div style="margin-top: 24px; font-size: 14px">
        //       ${this.$t("出库日期")}: ${this.printText(deliveryDate)}
        //   </div>
        // </div>`;

        //             LODOP.NewPage();
        //             LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
        //           }
        //         }

        //         LODOP.PREVIEW();
      });
    },
    openPrintPackingModal() {
      this.printPackingModalVisible = true;
    },
    printPackingNumber() {
      if (!this.packingNumber || this.packingNumber.length === 0) {
        this.$message.warning(this.$t("未输入打印箱号"));
        return;
      }

      if (!this.printQuantity || this.printQuantity <= 0) {
        this.$message.warning(this.$t("未输入打印张数"));
        return;
      }

      if (!this.packingLabelLength || !this.packingLabelWidth) {
        this.$message.warning(this.$t("请先在标签配置中设置标签参数!"));
        return;
      }

      const LODOP = getLodop();
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.packingLabelLength}mm`, `${this.packingLabelWidth}mm`);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 20);

      for (let index = 1; index <= this.printQuantity; index++) {
        let printNumber;
        if (this.printModel === "continuous") {
          printNumber = `${this.packingNumber}${String(index).padStart(3, "0")}`;
        } else {
          printNumber = this.packingNumber;
        }

        LODOP.NewPage();
        LODOP.ADD_PRINT_BARCODE(
          `${this.packingLabelWidth * 0.15}mm`,
          `${this.packingLabelLength * 0.15}mm`,
          `${this.packingLabelLength * 0.7}mm`,
          `${this.packingLabelWidth * 0.7}mm`,
          "128A",
          printNumber
        );
      }
      LODOP.PREVIEW();
    },
    printPackingOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的出库通知单!"));
        return;
      }

      printDeliveryPackingOrder({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("");
        LODOP.SET_PRINT_PAGESIZE(1, `"215mm"`, "279mm");

        const deliveryOrderItems = [];
        for (const item of data) {
          item.delivery_record_material_items = item.delivery_record_material_items.filter(
            (item) => item.packing_number && item.packing_number.length > 0
          );
          if (item.delivery_record_material_items.length > 0) {
            deliveryOrderItems.push(item);
          }
        }

        if (deliveryOrderItems.length === 0) {
          this.$message.warning(this.$t("仅支持复核包装后打印装箱单"));
          return;
        }

        for (const deliveryOrderItem of deliveryOrderItems) {
          let pages = Math.ceil(deliveryOrderItem.delivery_record_material_items.length / 14);
          let totalQuantity = 0;
          for (let item of deliveryOrderItem.delivery_record_material_items) {
            totalQuantity = NP.plus(totalQuantity, item.total_quantity);
          }

          for (let index = 1; index <= pages; index++) {
            let deliveryMaterialItems = deliveryOrderItem.delivery_record_material_items.slice((index - 1) * 8, index * 8);
            let tableRows = deliveryMaterialItems.map((item) => {
              return `
<tr>
  <td>${this.printText(item.packing_number)}</td>
  <td>${this.printText(item.material_number)}</td>
  <td>${this.printText(item.material_name)}</td>
  <td>${this.printText(item.material_barcode)}</td>
  <td>${this.printText(item.material_spec)}</td>
  <td>${this.printText(item.total_quantity)}</td>
</tr>`;
            });

            const pageContent = `
<div style="padding: 16px">
  <div style="font-size: 36px; text-align: center">
    <span>${this.$t("装 箱 单")}</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="font-size: 36px; margin-top: 8px; text-align: center">
    <span>PACKING LIST</span>
  </div>

  <div style="margin-top: 16px; display: flex">
    <div style="width: 50%">${this.$t("单号")}: ${this.printText(deliveryOrderItem.number)}</div>
    <div style="width: 50%">${this.$t("货主")}: ${this.printText(deliveryOrderItem.client_item.name)}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th>${this.$t("箱号")} <br /> Packing Number</th>
        <th>${this.$t("货品编号")} <br /> Product Number</th>
        <th>${this.$t("货品名称")} <br /> Product Name</th>
        <th>${this.$t("货品条码")} <br /> Product Barcode</th>
        <th>${this.$t("箱规")} <br /> Spec</th>
        <th>${this.$t("数量")} <br /> Qauntity</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td>${this.$t("合计")}: <br /> Total:</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>${this.printText(totalQuantity)}</td>
      </tr>
    </table>
  </div>
</div>
            `;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
